




































































































import "weui";
import { defineComponent } from "@vue/composition-api";
import { Toast, Dialog, List } from "vant";
import { debounce } from "lodash";

import AMapLoader from "@amap/amap-jsapi-loader";
import "@amap/amap-jsapi-types";
declare const AMap: AMap.NameSpace;

interface Cabinet {
  lat: number;
  lng: number;
  id: number;

  // AMap.MassData
  lnglat: AMap.LngLat;
  style?: number;
}

enum MarkStyle {
  Nothing,
  OnlineEbike,
  OfflineEbike
}

export default defineComponent({
  components: {
    [List.name]: List
  },
  data() {
    return {
      map: {} as AMap.Map,
      marker: {} as AMap.Marker,
      mass: {} as AMap.MassMarks,
      geolocation: {} as AMap.Geolocation,

      list: [] as Cabinet[],
      itemInfo: {} as Cabinet,
      listData: {
        loading: false,
        finished: false
      },
      infoList: [],
      refresher: null,
      page: 0,
      mapSearch: "",
      searchForm: {
        isRent: this.$route.query.isRent || "",
        isOnline: this.$route.query.isOnline || "",
        deviceType: this.$route.query.deviceType || 0,
        isShow: this.$route.query.isShow || 0
      },
      states: {
        showList: false,
        showFilter: false,
        showStat: false,
        showStatCount: false,
        showSearch: false
      },
      stat: {
        countsM: 0,
        rectangle: {} as AMap.Rectangle,
        firstPoint: {} as AMap.Marker,
        isOnFirstStep: true,
        southWestLat: 0,
        southWestLng: 0,
        northEastLng: 0,
        northEastLat: 0
      },
      getData: function() {} as _.DebouncedFunc<() => void>,
      zoom: 12
    };
  },
  watch: {
    "states.showList": "onListShowHide",
    "states.showSearch": "onSearchShowHide",
    "states.showStat": "onStatShowHide"
  },
  mounted() {
    const loading = Toast.loading("正在加载地图...");
    AMapLoader.load({
      key: process.env.VUE_APP_AMAP_KEY,
      version: process.env.VUE_APP_AMAP_VERSION,
      plugins: ["AMap.Geolocation"]
    })
      .then(amapLoader => {
        loading.clear();
        this.map = new amapLoader.Map("map", {
          resizeEnable: true,
          zoom: this.zoom,
          rotateEnable: false,
          zooms: [12, 19]
        });

        // 移动到上一次的位置
        const lastLng = localStorage.getItem("nowlng");
        const lastLat = localStorage.getItem("nowlat");
        if (lastLng && lastLat) {
          this.map.panTo([Number(lastLng), Number(lastLat)]);
        }

        // 设置拖拽结束保存位置
        this.map.on("dragend", () => {
          this.itemInfo.id = 0;
          const center = this.map.getCenter();
          localStorage.setItem("nowlng", center.lng + "");
          localStorage.setItem("nowlat", center.lat + "");
        });

        this.map.on("moveend", () => {
          this.getData();
        });
        this.map.on("zoomend", () => {
          const newZoom = this.map.getZoom();
          if (newZoom < this.zoom) {
            this.getData();
          }
          this.zoom = newZoom;
        });

        // 获取数据
        this.getData();
      })
      .catch(() => {
        Toast.fail("地图加载失败");
      });
  },
  created() {
    this.getData = debounce(this.getDateRaw, 500);
  },
  unmounted() {
    this.getData.cancel();
  },
  methods: {
    /**
     * 搜索
     */
    onSearchShowHide(show: boolean) {
      if (!show) return;
      this.$nextTick(() => {
        const loading = Toast.loading("正在加载地图插件");
        AMap.plugin(["AMap.PlaceSearch", "AMap.Autocomplete"], () => {
          loading.clear();
          const auto = new AMap.Autocomplete({
            input: "pre-search-input"
          });
          const placeSearch = new AMap.PlaceSearch({
            map: this.map
          });
          auto.on("select", (e: { poi: { name: string; adcode: number }; type: string }) => {
            this.states.showSearch = false;
            placeSearch.search(e.poi.name);
          });
        });
      });
    },
    /**
     * 筛选
     */
    applyFilter() {
      this.states.showFilter = false;
      this.mass.clear();
      this.getData();
    },
    /**
     * 定位
     */
    moveToCurrentLocation() {
      // 加载地图定位
      AMap.plugin(["AMap.Geolocation"], () => {
        if (this.$isEmptyObject(this.geolocation)) {
          const geolocation = new AMap.Geolocation({
            enableHighAccuracy: true,
            timeout: 10000, // ms
            showButton: false
          });
          this.map.addControl(geolocation);
          this.geolocation = geolocation;
        }
        this.geolocation.getCurrentPosition((status: string, result: { info: string; message: string; position: { lng: string; lat: string } }) => {
          if (status == "complete") {
            localStorage.setItem("nowlng", result.position.lng);
            localStorage.setItem("nowlat", result.position.lat);
          } else {
            Toast.fail("定位失败_" + result.info + "," + result.message);
          }
        });
      });
    },
    /**
     * 跳转任务列表
     */
    goTaskList(ebikeNo: string) {
      this.$toMiniAppWeb("/web/manager/task_list?deviceId=" + ebikeNo);
    },
    /**
     * 取电池
     */
    takeBattery(ebikeId: number, ebikeNo: string) {
      Dialog.confirm({
        title: "确认操作",
        message: "是否解锁电池？"
      }).then(() => {
        this.$axios
          .post("/api/manage/takeBattery", {
            qrCode: ebikeNo,
            ebikeId: ebikeId
          })
          .then(() => {
            Toast.success("解锁成功");
          });
      });
    },
    /**
     * 跳转车辆详情
     */
    goEbikeDetail(ebikeId: number) {
      if (!ebikeId) {
        Toast.fail("异常");
        return;
      }
      if (ebikeId != 0) {
        localStorage.setItem("inputType", "1");
        localStorage.setItem("inputValue", ebikeId + "");
        localStorage.setItem("searchType", "0");
        this.$toMiniAppWeb("/web/manager/device_detail.html");
      }
    },
    /**
     * 获取地图数据
     */
    getDateRaw() {
      const lnglat0 = this.map.containerToLngLat(new AMap.Pixel(0, 0));
      const lnglat = this.map.getCenter();
      this.$axios
        .post(
          "/api/manage/cabinet/getCabinetMap",
          Object.assign({}, this.searchForm, {
            lat0: lnglat0.lat,
            lng0: lnglat0.lng,
            lat: lnglat.lat,
            lng: lnglat.lng
          })
        )
        .then(res => {
          const e = res.data;
          const list: Cabinet[] = e.data.list;
          for (const n of list) {
            n.lnglat = new AMap.LngLat(n.lng, n.lat);
            n.style = MarkStyle.OnlineEbike;
          }
          this.list = list;
          this.mass = new AMap.MassMarks(list, {
            zIndex: 111,
            style: this.getStyles()
          });
          this.mass.setMap(this.map);
          const marker = new AMap.Marker({ content: " ", map: this.map });
          this.mass.on("click", e => {
            marker.setPosition(e.data.lnglat);
            marker.setLabel({ content: "当前", offset: new AMap.Pixel(0, -25), direction: "top" });
            this.getItemInfo(e.data.id);
          });
          this.marker = marker;
        });
    },
    /**
     * 获取单个车辆数据
     */
    getItemInfo(id: number) {
      Toast.fail(id + " not yet");
    },
    /**
     * 列表
     */
    onListShowHide(show: boolean) {
      if (show && this.infoList.length === 0) {
        if (this.list.length === 0) {
          Toast.fail("请先等待加载完毕");
        } else {
          this.getList(); // 触发第一次
        }
      }
    },
    getList() {
      const size = 20;
      const start = this.page * size;
      const end = Math.min((this.page + 1) * size, this.list.length);
      const isEnd = end === this.list.length;
      const rawList: Cabinet[] = this.list.slice(start, end);
      const idList: number[] = [];
      for (const n of rawList) {
        idList.push(n.id);
      }
      const ids = idList.join(",");
      if (!ids) {
        Toast.fail("错误");
        return;
      }
      this.listData.loading = true;
      this.$axios.post("/api/manage/getDeviceEbikeMapList", { ebikeIds: ids }).then(res => {
        this.page++;
        this.listData.loading = false;
        const e = res.data;
        this.infoList = this.infoList.concat(e.data.list);
        if (isEnd) {
          this.listData.finished = true;
        }
      });
    },
    /**
     * 统计
     */
    onStatShowHide(show: boolean) {
      if (show) {
        this.map.on("click", this.recordPoint);
      } else {
        this.stat.countsM = 0;
        this.map.off("click", this.recordPoint);
        this.map.remove(this.stat.firstPoint);
        // if (this.stat.firstPoint.remove) {
        // this.stat.firstPoint.remove();
        // }
        this.map.remove(this.stat.rectangle);
        // if (this.stat.rectangle.remove) {
        //   this.stat.rectangle.remove();
        // }
        this.stat.isOnFirstStep = true;
        this.states.showStatCount = false;
      }
    },
    recordPoint(e: { lnglat: AMap.LngLat }) {
      if (this.stat.isOnFirstStep) {
        this.stat.southWestLng = e.lnglat.getLng();
        this.stat.southWestLat = e.lnglat.getLat();
        const icon = new AMap.Icon({
          imageSize: new AMap.Size(30, 30),
          image: "/images/location.png"
        });
        this.stat.firstPoint = new AMap.Marker({
          icon: icon,
          position: [this.stat.southWestLng, this.stat.southWestLat],
          offset: new AMap.Pixel(-13, -30)
        });
        this.stat.firstPoint.setMap(this.map);
        this.map.remove(this.stat.rectangle);
        // if (this.stat.rectangle.remove) {
        //   this.stat.rectangle.remove();
        // }
        this.stat.isOnFirstStep = false;
      } else {
        this.stat.northEastLng = e.lnglat.getLng();
        this.stat.northEastLat = e.lnglat.getLat();
        this.setRectangle();
        this.stat.southWestLat = 0;
        this.map.remove(this.stat.firstPoint);
        this.stat.isOnFirstStep = true;
      }
    },
    setRectangle() {
      const x1 = Math.min(this.stat.southWestLng, this.stat.northEastLng);
      const y1 = Math.min(this.stat.southWestLat, this.stat.northEastLat);
      const x2 = Math.max(this.stat.southWestLng, this.stat.northEastLng);
      const y2 = Math.max(this.stat.southWestLat, this.stat.northEastLat);

      const southWest = new AMap.LngLat(x1, y1);
      const northEast = new AMap.LngLat(x2, y2);
      const bounds = new AMap.Bounds(southWest, northEast);
      this.stat.rectangle = new AMap.Rectangle({
        bounds: bounds,
        strokeColor: "red",
        strokeWeight: 6,
        strokeOpacity: 0.5,
        strokeDasharray: [30, 10],
        strokeStyle: "dashed",
        fillColor: "blue",
        fillOpacity: 0.2,
        cursor: "pointer",
        zIndex: 50
      });
      this.stat.rectangle.setMap(this.map);
      let countsM = 0;
      for (const n of this.list) {
        if (x1 < n.lng && n.lng < x2 && y1 < n.lat && n.lat < y2) {
          countsM++;
        }
      }
      this.stat.countsM = countsM;
      this.states.showStatCount = true;
    },
    /**
     * 点样式
     */
    getStyles(): AMap.MassMarkersStyleOptions[] {
      return [
        {
          url: "",
          anchor: new AMap.Pixel(0, 0),
          size: new AMap.Size(0, 0),
          rotation: 0
        },
        {
          url: "/images/chargeCabinet_orange.png",
          anchor: new AMap.Pixel(15, 30),
          size: new AMap.Size(30, 30),
          rotation: 0
        }
      ];
    }
  }
});
